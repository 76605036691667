export default [
  {
    path: '/dashboard',
    name: 'dashboard-partner',
    component: () => import('@/views/Partner/dashboard/dashboard.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-lotto-type',
    name: 'manage-lotto-type',
    component: () => import('@/views/Partner/manage/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/Partner/deposit/deposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/Partner/withdraw/withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member',
    name: 'member',
    component: () => import('@/views/Partner/member/member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member-add',
    name: 'member-add',
    component: () => import('@/views/Partner/member/add.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member-edit/:id',
    name: 'member-edit',
    component: () => import('@/views/Partner/member/add.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/list-confirm',
    name: 'list-confirm',
    component: () => import('@/views/Partner/history/list_confirm.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Withdraw/:id',
    name: 'Deposit-Withdraw',
    component: () => import('@/views/Partner/history/deposit_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend-vip',
    name: 'invite-friend-vip',
    component: () => import('@/views/Partner/invite_friend/view.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/invite-detail/:id',
    name: 'invite-detail',
    component: () => import('@/views/Partner/invite_friend/detail.vue'),
    meta: {
      // layout: 'full',
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail-zean',
    name: 'invite-detail-zean',
    component: () => import('@/views/Partner/invite_friend/detail.vue'),
    meta: {
      layout: 'full',
      resource: 'Zean',
      action: 'read',
    },
  },
  {
    path: '/invite-detail-all',
    name: 'invite-detail-all',
    component: () => import('@/views/Partner/invite_friend/detail_all.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Lotto',
    name: 'lotto',
    component: () => import('@/views/Partner/lotto/lotto.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/LottoAgent',
    name: 'LottoAgent',
    component: () => import('@/views/Partner/lotto/lotto_agentgroup.vue'),
    meta: {
      resource: 'AGgroup',
      action: 'read',
    },
  },
  {
    path: '/Group',
    name: 'group',
    component: () => import('@/views/Partner/lotto/group.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/Type',
    name: 'type',
    component: () => import('@/views/Partner/lotto/type.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/manage-time',
    name: 'manage-time',
    component: () => import('@/views/Partner/lotto/manage-time.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/Partner/lotto/pay.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/rate',
    name: 'rate',
    component: () => import('@/views/Partner/lotto/rate.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/set-bet',
    name: 'setbet',
    component: () => import('@/views/Partner/lotto/setbet.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/descending',
    name: 'descending',
    component: () => import('@/views/Partner/descending.vue'),
    meta: {
      resource: 'descending',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/Partner/lotto/ref.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/History-Play/:id',
    name: 'History-Play',
    component: () => import('@/views/Partner/history/play.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/win-loss',
    name: 'win-loss',
    component: () => import('@/views/Partner/history/winloss.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/win-loss-user',
    name: 'win-loss-user',
    component: () => import('@/views/Partner/history/winlossuser.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-all',
    name: 'sms-all',
    component: () => import('@/views/Partner/sms/smsAll.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-deposit',
    name: 'sms-deposit',
    component: () => import('@/views/Partner/sms/smsDeposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-withdraw',
    name: 'sms-withdraw',
    component: () => import('@/views/Partner/sms/smsWithdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-otp',
    name: 'sms-otp',
    component: () => import('@/views/Partner/sms/otp.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import('@/views/Partner/sms/statement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement-kbank',
    name: 'statement-kbank',
    component: () => import('@/views/Partner/statement/statement_Kbank.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/true-money',
    name: 'true-money',
    component: () => import('@/views/Partner/sms/trueMoney.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/Partner/admin/bank.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/Partner/admin/promotion.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('@/views/Partner/admin/channel.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/admin-management',
    name: 'admin-management',
    component: () => import('@/views/Partner/admin/admin.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/Partner/admin/setting.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('@/views/Partner/admin/agent-list.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/Partner/admin/agent.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/manage-agent/:id',
    name: 'manage-agent',
    component: () => import('@/views/Partner/admin/agent-manage/view.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  // {
  //   path: '/manage-agent/:id',
  //   name: 'manage-agent',
  //   component: () => import('@/views/Partner/admin/manage-agent.vue'),
  //   meta: {
  //     resource: 'Admin',
  //     action: 'read',
  //   },
  // },
  {
    path: '/report-win-lose',
    name: 'report',
    component: () => import('@/views/Partner/admin/reports.vue'),
    meta: {
      resource: 'Agadmin',
      action: 'read',
    },
  },
  {
    path: '/scb-capcha/:ID',
    name: 'scb',
    component: () => import('@/views/Partner/scb.vue'),
    meta: {
      layout: 'full',
      // redirectIfLoggedIn: false,
      resource: 'Auth',
    },
  },
]
